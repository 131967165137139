import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; // For React Router v5 or v6
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { baseUrl } from '../Service/Register';

const OrderDetails = () => {
    const { id } = useParams();
    const [Data, setData] = useState([]);


    const getOrder = async () => {
        const formData2 = new FormData();
        formData2.append("orderNo", id);

        try {
            const response = await fetch(`${baseUrl}Order/GetAllImagesOfOrder`,
                {
                    method: "POST",
                    body: formData2,
                    headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}` }
                });

            const data = await response.json();
            if (data && data.success === true) {
                const result = data.data;
                setData(result);

            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
        }
    };

 
    

    useEffect(() => {
        getOrder();
        window.scrollTo(0, 0);
    }, []);
    console.log(Data.primary)
    return (
        <>
            <Header />
            <center>
                <section className="aboutBanner">
                    <img src="/assets/img/Mosaico-Foto.webp" className="img-fluid" alt="Banner" />
                </section>
                <section class="galleryBox">

                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Primary Photo</h2>
                                </div>
                                <div className="gallery-images">
                                    <Link
                                        to={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                        data-fancybox="gallery"
                                    >
                                        <img
                                            src={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                            alt='Documents'
                                            className="img-fluid"
                                            style={{ marginRight: '10px' }}
                                        />
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Cell Photos</h2>
                                </div>
                                <div className="gallery-images">
                                    <Link
                                        to={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                        data-fancybox="gallery"
                                    >
                                        <img
                                            src={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                            alt='Documents'
                                            className="img-fluid"
                                            style={{ marginRight: '10px' }}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                    {/* <div class="container">
                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Primary Photo</h2>
                                </div>
                                <div className="gallery-images">
                                    <Link
                                        to={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                        data-fancybox="gallery"
                                    >
                                        <img
                                            src={`https://api.mosaicphotos.com.au/${Data.primary}`}
                                            alt='Documents'
                                            className="img-fluid"
                                            style={{ marginRight: '10px' }}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-10 mx-auto">
                                <div class="titleBox">
                                    <h2>Cell Photos</h2>
                                </div>
                                <div className="gallery-images">
                                    {Data.secondary && Data.secondary.map((image, index) => (
                                        <Link
                                            key={index}
                                            to={`https://api.mosaicphotos.com.au/${image}`}
                                            data-fancybox="gallery"
                                        >
                                            <img
                                                src={`https://api.mosaicphotos.com.au/${image}`}
                                                alt={`Cell Photo ${index + 1}`}
                                                className="img-fluid"
                                                style={{ marginRight: '10px' }}
                                            />
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div> */}

                </section>
            </center>
            <Footer />
        </>
    );
}

export default OrderDetails;
