import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { baseUrl } from '../Service/Register';
import axios from 'axios';
import { toast } from 'react-toastify';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleScroll = (event) => {
        event.preventDefault();
        const element = document.getElementById('faqContact');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [formData, setFormData] = useState({
        Name: '',
        EmailId: '',
        PhoneNo: '',
        Message: '',
        WhereHear: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a new FormData object
        const form = new FormData();

        // Append form data fields to FormData
        form.append('Name', formData.Name);
        form.append('EmailId', formData.EmailId);
        form.append('PhoneNo', formData.PhoneNo);
        form.append('Message', formData.Message);
        form.append('WhereHear', formData.WhereHear);



        try {
            // Send the form data via POST request using axios
            const response = await axios.post(`${baseUrl}Registration/AddContactUs`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Reset form data after successful submission
            setFormData({
                Name: '',
                EmailId: '',
                PhoneNo: '',
                Message: '',
                WhereHear: '',
            });
            toast.success('Form submitted successfully');
        } catch (error) {
            const errorMessage = error.response ? error.response.data : 'Error sending form data';
            toast.error(`Error sending form: ${errorMessage}`);
            console.error('Form submit error:', error);
        }
    };



    const handleKeyPress = (e) => {
        // Allow only digits (0-9)
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <>
            <Header />
            {/* <!-- Contact --> */}

            <div className="concontainer">
                <div className="row">
                    <center>
                        <h2 className="conh2">Contact Us </h2>
                        <p className="conp"> At Mosaic Photos Australia, we value your satisfaction above all else. Your feedback and comments are essential to our success. Here’s how you can reach us: <br />

                            If you have any questions, feel free to explore our <a href="#faqContact" onClick={handleScroll}>Frequently Asked Questions</a> section or reach out to us via email or phone.

                        </p>
                    </center>
                    <div className="col-md-6 contact-form">
                        <h2>Send us a message
                        </h2>
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange} required />
                            <input type="email" name="EmailId" placeholder="Email" value={formData.EmailId} onChange={handleChange} required />
                            <input type="tel" maxLength={10} minLength={10} onKeyPress={handleKeyPress} name="PhoneNo" placeholder="Phone Number" value={formData.PhoneNo} onChange={handleChange} required />
                            <textarea name="Message" rows="4" placeholder="Message" value={formData.Message} onChange={handleChange} required></textarea>
                            <select name="WhereHear" value={formData.WhereHear} onChange={handleChange} required>
                                <option value="" disabled hidden>How did you hear about us?</option>
                                <option value="Google">Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                            </select>
                            <button type="submit">Send Message</button>
                        </form>
                    </div>
                    <div className="col-md-6 social-section">
                        <ul className="csocial-icons">
                            <h2>Contact Informations
                            </h2>

                            <li className="cmail">
                                <div>
                                    <span></span>
                                    <cmails className="cmailsinfo">
                                        <strong>General enquiries:</strong> info@mosaicphotos.com.au
                                        <br />
                                        <strong>Order tracking:</strong> sales@mosaicphotos.com.au <br />
                                        <strong>Phone:</strong> 0416 273 308 <br />
                                        <strong> International:</strong> +61 416 273 308 <br />
                                        <strong>  Postal Address:</strong> 6 Bobbie Court, Dayboro Q 4521 <br />
                                        <strong>Business Hours:</strong> 8am to 6pm (AEST)
                                        7 days a week, 365 days a year.
                                    </cmails>


                                </div>
                            </li>


                            <li className="cfacebook">
                                <a href="https://www.facebook.com/MosaicPhotosAustralia" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"></i>
                                    <div>
                                        <span>Follow us on Facebook</span>
                                        <span className="username">Mosaic Photos Australia </span>
                                    </div>
                                </a>
                            </li>
                            <li className="cinstagram">
                                <a href="https://www.instagram.com/mosaic.photos.australia/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram"></i>
                                    <div>
                                        <span>Follow us on Instagram</span>
                                        <span className="username">mosaic.photos.australia</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* FAQ */}
            <div className="rrow" id='faqContact'>
                <div className="faq-container">
                    <h4 className="faq-heading">Any Questions?</h4>
                    <Accordion className="faq-item1" >

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>What is a Mosaic Photo?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>A mosaic photo (also known as a photo-mosaic)
                                is a large photo made up of smaller photos.
                                Each pixel is represented by a small photo,
                                known as the cell photo. When viewed from a
                                distance, the individual pixels appear as
                                the primary image, while close examination
                                reveals that the image is in fact made up of
                                many hundreds or thousands of smaller images.</p>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >    <h6>What do I get with my order?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>You will receive a personally customised mosaic photo, printed on quality photo paper or canvas. On request, we can also send the full size image. But be aware, this is a very large file.</p></AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        > <h6>100% satisfaction, guaranteed!</h6></AccordionSummary>

                        <AccordionDetails>   <p>Before any money has changed hands, we will send you a digital preview via email. If you are not 100% happy with how it looks, let us know and we will change it. Your happiness is our goal.</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How does the order process work?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>The order process works in a few easy steps. Create an account or login. Upload your favourite photo as the primary photo. Upload a collection of photos used for the small background photos (these are your cell photos). Choose a print method (digital, paper, canvas etc) and size then submit your mosaic. Simple!</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How many photos do I need to make up a good Mosaic?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>You could literally make a mosaic with any number of photos, but as a general rule, the more photos the better. Aim for at least 200 photos to reduce the number of duplicated cells photos. The larger the photo, the more cell photos you will need. An average Mosaic of around 16" x 20" can display up to 1500 photos, but would only need 300 photos to reduce the number of noticeable duplicates.
                                All care is taken to ensure that duplicates are spaced away from each other, so you shouldn't notice any duplicates unless you are looking hard to find them.</p>
                        </AccordionDetails>
                    </Accordion>
                    <center>
                        <Link to="/Faq"> <button>
                            See More
                        </button>
                        </Link>
                    </center>
                </div>
            </div>
            {/* FAQ */}
            <Footer />
        </>
    )
}

export default Contact
