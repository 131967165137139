import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { GETAPI, POSTAPI } from "../Service/Serivce";
import Preloader from "../Preloader";
import { baseUrl } from "../Service/Register";
import { toast } from "react-toastify";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const formatDate = (isoString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", options);
  };

  const getView = async () => {
    try {
      const result = await POSTAPI("Order/GetAllTestimonalRecords");
      if (result) {
        setData(result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getView();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="hero-container">
        <div className="hero-column">
          <div>
            <img src="assets/img/banner-A.png" className="imban1" />
            <h2 className="heroh2">
              Welcome To Mosaic <br /> Photos Australia
            </h2>
            <p className="herop">
              Stunning mosaic photographs make unique photo gifts that will
              impress even the hardest critics. At Mosaic Photos Australia, we
              make it easy for you to take all of your favourite digital
              pictures and create your own personalised photomosaic.
            </p>
            <button>
              <a
                href="/create"
                style={{ textDecoration: "none", color: "white" }}
              >
                Create My Mosaic Photo
              </a>
            </button>
          </div>
        </div>
        <div className="hero-column">
          <div className="gif-container">
            <img
              src="assets/img/Baby.jpeg"
              // src="https://webdroy.com/brick/assets/img/hero1.gif"
              alt="Hero GIF"
            />
          </div>
        </div>
      </section>
      {/* About Section*/}
      <div className="about">
        <h2 className="abh2"> About Us</h2>
        <p className="abp">
          [foh-toh-moh-zey-ik] In the field of photographic imaging, a mosaic
          photograph (also known under the term Photomosaic, a portmanteau of
          photo and mosaic) is a picture that has been divided into sections,
          each of which is replaced with another photograph of appropriate
          average colour.
        </p>
      </div>
      {/* Create Section*/}
      <div className="create">
        <h3 className="crh3">
          <strong>
            Order your photo mosaic in 3 <br /> simple steps
          </strong>
        </h3>
      </div>
      {/* Steps */}
      <div className="grid-container">
        <div>
          <h4 className="ch4">01</h4>
          <img src="assets/img/Icon-1.png" className="imicon" />
          <h6 className="ch6">Upload Photos</h6>
          <p className="cp">Collect the photos you want to upload</p>
        </div>
        <div>
          <h4 className="ch4">02</h4>
          <img src="assets/img/icon-2.png" className="imicon" />
          <h6 className="ch6">Select Layout</h6>
          <p className="cp">Choose your desired layout</p>
        </div>
        <div>
          <h4 className="ch4">03</h4>
          <img src="assets/img/icon-3.png" className="imicon" />
          <h6 className="ch6">Order Prints</h6>
          <p className="cp">Place your order for prints</p>
        </div>
      </div>
      {/* How It Work */}
      <div className="workrow">
        <div className="workcolumn">
          <h3 className="workh3">How It Works</h3>
          <p className="workp">
            Your Digital Treasures: Gather your favorite digital snapshots—the
            moments that matter most to you.
            <br />
            Create Your Masterpiece: With our user-friendly ordering system,
            upload your photos. We’ll handle the rest, meticulously arranging
            each image.
            <br />
            Free Sample: Yes, you read that right! We offer a no-obligations
            sample—because we’re confident you’ll fall in love with what you
            see.
            <br />
          </p>
          <button>
            {" "}
            <a href="/about" style={{ color: "white" }}>
              Learn More
            </a>
          </button>
        </div>
        <div className="workcolumn">
          <img
            src="assets/img/how-it-work-new.png"
            className="workimg"
            alt="How it works"
          />
        </div>
      </div>
      {/*- product*/}
      <br />
      <div>
        <center>
          <h3 className="proh3">Products And Services</h3>
        </center>
      </div>
      <section className="pt-5 circle-1">
        <div className="container pb-5 position-relative">
          <div
            id="demo"
            className="carousel slide position-relative"
            data-ride="carousel"
          >
            {/* Indicators */}
            <ul className="carousel-indicators">
              <li data-target="#demo" data-slide-to={0} className="" />
              <li data-target="#demo" data-slide-to={1} className="active" />
              <li data-target="#demo" data-slide-to={2} className="" />
            </ul>
            {/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active carousel-item-left">
                <div className="d-flex">
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-1.png"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Block Mount Prints </h4>
                      <p className="pricep"> $82.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-2.png"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Acrylic Prints </h4>
                      <p>From $125.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-3.png"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Framed Prints </h4>
                      <p>From $148.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                </div>
              </div>
              <div className="carousel-item carousel-item-next carousel-item-left">
                <div className="d-flex">
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-4.png"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Floatong Frame Canvas</h4>
                      <p>From $149.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-5.jpg"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Digital Only </h4>
                      <p>From $27.50</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-6.jpg"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Photographic Prints</h4>
                      <p>From $61.50</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="d-flex">
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-7.jpg"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Canvas Prints </h4>
                      <p>From $97.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column ">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-8.jpg"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Metal Prints</h4>
                      <p>From $139.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex flex-column">
                    <div className="flex-column-1 ">
                      <img
                        src="assets/img/product-9.jpg"
                        style={{ height: 150, width: 150 }}
                      />
                    </div>
                    <center>
                      <h4 className="priceh4">Photo Wraps </h4>
                      <p>From $95.00</p>
                      <div className="button-container">
                        <button className="start-order">
                          <a
                            href="/create"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Start An Order
                          </a>
                        </button>
                        <button className="another-button" href="#">
                          Submit An Order
                        </button>
                      </div>
                    </center>
                    <div className="flex-column-2 d-flex"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* Left and right controls */}
            <a
              className="carousel-control-prev"
              href="#demo"
              data-slide="prev"
              style={{ opacity: 1, left: "-10%" }}
            >
              <i
                className="fas fa-chevron-left"
                style={{ color: "#5969f6", fontSize: 30 }}
              />
            </a>
            <a
              className="carousel-control-next"
              href="#demo"
              data-slide="next"
              style={{
                opacity: 1,
                transform: "rotate(-180deg)",
                right: "-10%",
              }}
            >
              <i
                className="fas fa-chevron-left"
                style={{ color: "#5969f6", fontSize: 30 }}
              />
            </a>
          </div>
        </div>
      </section>
      {/* Reviews*/}
      <div className="rrow">
        <h3 className="reviewh3">Reviews</h3>
        <div className="rcontainer">
          <div className="rcolumn review">
            <div className="rtestimonial">
              <div className="rtestimonial-info">
                <div className="rtestimonial-image">
                  <img src="assets/img/reviews.png" alt="Testimonial Image" />
                </div>
                <div className="rtestimonial-details">
                  <h4 className="rname"> Jane Smith </h4>
                  <div className="rrating">⭐⭐⭐⭐⭐</div>
                  <p className="rcomment">best service provider.</p>
                  <p className="rdate">Michelle S. – Sept 3, 2023</p>
                  <img src="assets/img/verify.svg" />
                  <a className="rverify">Verified Buyer</a>
                </div>
              </div>
            </div>
          </div>
          <div className="rcolumn review">
            <div className="rtestimonial">
              <div className="rtestimonial-info">
                <div className="rtestimonial-image">
                  <img src="assets/img/Reviews-2.png" alt="Testimonial Image" />
                </div>
                <div className="rtestimonial-details">
                  <h4 className="rname"> Jane Smith </h4>
                  <div className="rrating">⭐⭐⭐⭐⭐</div>
                  <p className="rcomment">best service provider.</p>
                  <p className="rdate">Michelle S. – Sept 3, 2023</p>
                  <img src="assets/img/verify.svg" />
                  <a className="rverify">Verified Buyer</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Gallery*/}
      <div className="gallery">
        <center>
          <h3 className="workh3">Photo Gallery</h3>
          <img
            src="assets/img/gallery.png"
            alt="gallery"
            style={{ padding: "20px 10px", height: "auto", width: "90%" }}
            className="galleryimg"
          />
        </center>
      </div>
      {/*- Google Reviews*/}
      <div>
        <center>
          <h3 className="workh3">Google Reviews</h3>
          ⭐⭐⭐⭐⭐
          <p className="greviewp">
            Based on <span> 345</span> reviews
          </p>
        </center>
      </div>
      <br />
      <div className="greviews-container">
        <div className="greviews-wrapper">
          {Data &&
            Data.map((item) => (
              <div className="greview">
                <div className="guser-info">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcGKCd_GQUOkGs0jEYe4teajZ1W6z9WGZIB2pOKPIiYw&s"
                    alt="User 1"
                  />
                  <div>
                    <div className="gname">{item.customerName}</div>
                    <div className="gdate">
                      {formatDate(item.entryDate)}
                      <br /> ⭐⭐⭐⭐⭐
                    </div>
                  </div>
                </div>
                <div className="gcomment">{item.description}</div>
              </div>
            ))}

          <div className="greview">
            <div className="guser-info">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcGKCd_GQUOkGs0jEYe4teajZ1W6z9WGZIB2pOKPIiYw&s"
                alt="User 1"
              />
              <div>
                <div className="gname">Ravi Verma</div>
                <div className="gdate">
                  January 1, 2024 <br /> ⭐⭐⭐⭐⭐
                </div>
              </div>
              <div className="ggoogle-logo">
                <span />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                  alt="Google"
                />
              </div>
            </div>
            <div className="gcomment">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
          <div className="greview">
            <div className="guser-info">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcGKCd_GQUOkGs0jEYe4teajZ1W6z9WGZIB2pOKPIiYw&s"
                alt="User 1"
              />
              <div>
                <div className="gname">Ravi Verma</div>
                <div className="gdate">
                  January 1, 2024 <br /> ⭐⭐⭐⭐⭐
                </div>
              </div>
              <div className="ggoogle-logo">
                <span />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                  alt="Google"
                />
              </div>
            </div>
            <div className="gcomment">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
          <div className="greview">
            <div className="guser-info">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcGKCd_GQUOkGs0jEYe4teajZ1W6z9WGZIB2pOKPIiYw&s"
                alt="User 1"
              />
              <div>
                <div className="gname">Ravi Verma</div>
                <div className="gdate">
                  January 1, 2024 <br /> ⭐⭐⭐⭐⭐
                </div>
              </div>
              <div className="ggoogle-logo">
                <span />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                  alt="Google"
                />
              </div>
            </div>
            <div className="gcomment">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
        </div>
        <div className="gnav-btn gleft" onclick="gprevReview()">
          ❮
        </div>
        <div className="gnav-btn gright" onclick="gnextReview()">
          ❯
        </div>
      </div>
      <br />
      {/* FAQ */}
      <div className="rrow">
        <div className="faq-container">
          <h4 className="faq-heading">Any Questions?</h4>
          <Accordion className="faq-item1">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h6>What is a Mosaic Photo?</h6>
            </AccordionSummary>

            <AccordionDetails>
              <p>
                A mosaic photo (also known as a photo-mosaic) is a large photo
                made up of smaller photos. Each pixel is represented by a small
                photo, known as the cell photo. When viewed from a distance, the
                individual pixels appear as the primary image, while close
                examination reveals that the image is in fact made up of many
                hundreds or thousands of smaller images.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="faq-item1">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {" "}
              <h6>What do I get with my order?</h6>
            </AccordionSummary>

            <AccordionDetails>
              <p>
                You will receive a personally customised mosaic photo, printed
                on quality photo paper or canvas. On request, we can also send
                the full size image. But be aware, this is a very large file.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="faq-item1">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {" "}
              <h6>100% satisfaction, guaranteed!</h6>
            </AccordionSummary>

            <AccordionDetails>
              {" "}
              <p>
                Before any money has changed hands, we will send you a digital
                preview via email. If you are not 100% happy with how it looks,
                let us know and we will change it. Your happiness is our goal.
              </p>
            </AccordionDetails>
          </Accordion>

          <Accordion className="faq-item1">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h6>How does the order process work?</h6>
            </AccordionSummary>

            <AccordionDetails>
              <p>
                The order process works in a few easy steps. Create an account
                or login. Upload your favourite photo as the primary photo.
                Upload a collection of photos used for the small background
                photos (these are your cell photos). Choose a print method
                (digital, paper, canvas etc) and size then submit your mosaic.
                Simple!
              </p>
            </AccordionDetails>
          </Accordion>

          <Accordion className="faq-item1">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h6>How many photos do I need to make up a good Mosaic?</h6>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                You could literally make a mosaic with any number of photos, but
                as a general rule, the more photos the better. Aim for at least
                200 photos to reduce the number of duplicated cells photos. The
                larger the photo, the more cell photos you will need. An average
                Mosaic of around 16" x 20" can display up to 1500 photos, but
                would only need 300 photos to reduce the number of noticeable
                duplicates. All care is taken to ensure that duplicates are
                spaced away from each other, so you shouldn't notice any
                duplicates unless you are looking hard to find them.
              </p>
            </AccordionDetails>
          </Accordion>
          <center>
            <Link to="/Faq">
              {" "}
              <button>See More</button>
            </Link>
          </center>
        </div>
      </div>
      {/* FAQ */}
      <br />
      <Footer />
    </>
  );
};

export default Home;
