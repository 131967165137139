import React, { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaArrowRotateRight } from "react-icons/fa6";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import imageCompression from 'browser-image-compression'; // Import the compression library
import Preloader from "../Preloader";
import { baseUrl } from "../Service/Register";
import { toast } from "react-toastify";

const ImageUpload = () => {
  const [uploadImage, setUploadImage] = useState("");
  const [btn, setBtn] = useState(false);
  const [allImage, setAllImage] = useState([]);
  const [rotationAngles, setRotationAngles] = useState([]);
  const [CImages, setCImages] = useState([]); // State for compressed images
  const [imageNames, setImageNames] = useState([]); // State for image names
  const [loading, setLoading] = useState(false);

  const handleImage = async (event) => {
    const files = event.target.files;
    if (files) {
      const selected = Array.from(files);
      const previews = [];
      const compressedImages = [];
      const names = [];
      const initialAngles = selected.map(() => 0);

      setRotationAngles((prevAngles) => [...prevAngles, ...initialAngles]);
      setLoading(true)
      for (const file of selected) {
        names.push(file.name);

        try {

          const options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 500,
          };
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            const base64Data = base64String.split(',')[1]; // Remove the data:image/jpeg;base64, part

            compressedImages.push({ base64: base64Data });
            previews.push(base64String);

            if (previews.length === selected.length) {
              setAllImage((prevPreviews) => [...prevPreviews, ...previews]);
              setCImages(compressedImages);
              setImageNames(names);
              setLoading(false);
            }
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error("Image compression failed:", error);
        }
      }

      const ImgformData1 = new FormData();
      const payload = [{
        formFile: compressedImages,
        OrderNo: localStorage.getItem('OrderNo'),
      }];

      ImgformData1.append("cell", JSON.stringify(payload));
      try {
        const response1 = await fetch(`${baseUrl}Order/SaveCellImage`, {
          method: 'POST',
          body: ImgformData1,

        });
        if (!response1.ok) {
          throw new Error('Network response was not ok');
        }
        const result1 = await response1.json();
        toast.success(result1.message);
        if (result1.message) {
          setBtn(true)
        }
      } catch (error) {
        console.error('Error saving cell image:', error);
        toast.error('Failed to save cell image');
      }

      setUploadImage(true);
    }
  };

  const deleteImage = (indexToDelete) => {
    const updatedImages = allImage.filter((_, index) => index !== indexToDelete);
    const updatedCImages = CImages.filter((_, index) => index !== indexToDelete);
    const updatedNames = imageNames.filter((_, index) => index !== indexToDelete);


    setAllImage(updatedImages);
    setCImages(updatedCImages);
    setImageNames(updatedNames);
    sessionStorage.setItem("CImages", JSON.stringify(updatedCImages));
    sessionStorage.setItem("ImageNames", JSON.stringify(updatedNames));
    setRotationAngles((prevAngles) =>
      prevAngles.filter((_, index) => index !== indexToDelete)
    );
  };

  const rotateImage = (index) => {
    const newAngles = [...rotationAngles];
    newAngles[index] = (newAngles[index] + 90) % 360;
    setRotationAngles(newAngles);
  };
  if (loading === true) {
    return <Preloader />
  }
  return (
    <>
      <Header />
      <center>
        <div className="main-container">
          <div className="upload-container">
            <h2>Upload Your "Cell Photos"</h2>
            <p style={{ color: "blue" }}>
              Press the add button below to select your Cell Photos and then press the Upload button at the bottom.
            </p>
            <p style={{ color: "red", marginTop: "-25px" }}>
              We recommend a minimum of 50 photos
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={40}
              height={40}
              fill="currentColor"
              className="bi bi-file-arrow-up"
              viewBox="0 0 16 16"
            >
              <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
            </svg>{" "}
            <br />
            <h2>Upload Image</h2>
            <input
              type="file"
              onChange={handleImage}
              multiple
              id="file-upload"
              accept="image/*"
            />
            <label htmlFor="file-upload">Add Photo</label>
          </div>

        </div>
        {btn ? <a href="/frame">
          <button> Continue </button>
        </a> : 'Kindly hold off until all images have been uploaded.'}

        <br />

        {allImage.length > 0 && (

          <div className="main-container">
            <div className="upload-container">
              <div className="grid-container1">
                {allImage &&
                  allImage.map((val, index) => (
                    <div key={index}>
                      <img
                        src={val}
                        style={{
                          transform: `rotate(${rotationAngles[index]}deg)`,
                        }}
                      />
                      <div className="rotate">
                        <RiDeleteBin6Line
                          onClick={() => deleteImage(index)}
                        />
                        <FaArrowRotateRight
                          onClick={() => rotateImage(index)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <p style={{ marginTop: "20px", textAlign: "left" }}>
                Total Image: {allImage.length}
                {sessionStorage.setItem('Length', allImage.length)}
              </p>
            </div>
          </div>
        )}
        <div className="spacer" />
        <div className="spacer" />
        <div className="info-text">
          “What photos pictures are best for creating a
          photo-mosaic.<a href="/faq">Read our tips</a>
        </div>
        <div className="overlay" id="overlay" />
        <div className="popup" id="popup">
          <h2>Image Library</h2>
          <p>Your uploaded images will appear here.</p>
          <button className="popup-close" id="popup-close">
            Close
          </button>
        </div>
      </center>
      <Footer />
    </>
  );
};

export default ImageUpload;
