import React, { useEffect, useState, useRef } from "react";

const PayPalButton = ({ amount, onSuccess, orderId }) => {
  const [payPalScriptLoaded, setPayPalScriptLoaded] = useState(false);
  const paypalButtonRef = useRef(null); // Ref to uniquely reference each PayPal button container

  // Load PayPal script once when the component mounts
  useEffect(() => {
    if (!window.paypal && !payPalScriptLoaded) {
      const script = document.createElement("script");
      script.src = "https://www.paypal.com/sdk/js?client-id=AefyN4tH5Maxi_V3yt4Ug6MfqnrNKPSk5ByZofbD8YlLR3s-UySAxgUhVjLCvkNcCc8sWr64bTqYagDu&components=buttons";
      script.async = true;
      script.onload = () => {
        setPayPalScriptLoaded(true); // Mark script as loaded
      };
      script.onerror = () => {
        console.error("PayPal SDK script failed to load.");
      };
      document.body.appendChild(script);
    } else {
      setPayPalScriptLoaded(true); // If already loaded, set the state
    }
  }, [payPalScriptLoaded]);

  // Initialize PayPal Buttons once the script is loaded
  useEffect(() => {
    if (payPalScriptLoaded && window.paypal && paypalButtonRef.current) {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount, // Amount to be paid
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            console.log("Payment successful", details);
            onSuccess(details);
          });
        },
        onError: (err) => {
          console.error("PayPal payment error", err);
        },
      }).render(paypalButtonRef.current); // Render PayPal button in the unique ref container
    }
  }, [payPalScriptLoaded, amount, onSuccess, orderId]); // Dependencies include orderId to ensure button is uniquely rendered per order

  return <div ref={paypalButtonRef}></div>; // Render PayPal button inside unique ref container
};

export default PayPalButton;
