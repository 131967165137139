import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="widget1">
                <div className="logo">
                  <img
                    src="/assets/Logo/Mosaic-Photos-Logo.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div className="socialLinks">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/MosaicPhotosAustralia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/mosaic.photos.australia/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fab fa-instagram"
                          style={{ color: "white" }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget3">
                <ul>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li>
                    <Link to="/create">CREATE</Link>
                  </li>
                  <li>
                    <Link to="/gallery">GALLERY</Link>
                  </li>
                  <li>
                    <Link to="/about">ABOUT</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget3"></div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="widget4">
                <ul>
                  <li>
                    <Link to="/privacy">PRIVACY</Link>
                  </li>
                  <li>
                    <Link to="/terms">TERMS</Link>
                  </li>
                  <li>
                    <Link to="/contact">CONTACT</Link>
                  </li>
                  <li>
                    <Link to="/Faq">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRightArea">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p>© 2020 Mosaic Photos Australia</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
