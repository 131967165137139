import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Preview = () => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: 'USD',
      },
    });
  };

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "8.99",
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  return (
    <>
      <Header />
      <center>
        <section class="aboutBanner">
          <img src="assets/img/Mosaico-Foto.webp" class="img-fluid" />
        </section>
        <section class="aboutContent">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 mx-auto">
                <div class="titleBox">
                  <h2>Preview</h2>
                </div>
                <div class="aboutContent-box">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec non tempor nisi. Donec consequat arcu eget est
                    accumsan, eu posuere lorem dignissim. Nullam pretium
                    fringilla mi at dictum. Integer ut lacus dictum, vestibulum
                    augue vitae, placerat urna. Phasellus at viverra orci,
                    mollis lacinia enim. Fusce dui risus, commodo sit amet
                    viverra at, aliquam eu urna. Integer ut massa commodo,
                    consectetur nibh pellentesque, sodales urna.
                  </p>

                  <p>
                    Phasellus eget massa justo. Integer vitae neque imperdiet,
                    dapibus erat ut, interdum lectus. Etiam mattis pretium diam
                    mollis laoreet. Integer ipsum eros, tristique sit amet
                    tellus nec, interdum sodales sapien. Ut volutpat dapibus
                    luctus. Pellentesque hendrerit quam quis metus pulvinar, vel
                    ultricies quam convallis. In sed tempor sem. Donec feugiat
                    feugiat cursus. Nam lobortis non arcu nec vulputate.
                    Vestibulum fermentum malesuada libero, et tristique dui
                    vestibulum eu. Curabitur leo libero, interdum vel ante id,
                    eleifend faucibus nibh. Mauris sed felis et nibh luctus
                    accumsan.
                  </p>

                  <div className="checkout">
                    {isPending ? (
                      <p>LOADING...</p>
                    ) : (
                      <>
                       
                        <PayPalButtons
                          style={{ layout: "vertical" }}
                          createOrder={(data, actions) =>
                            onCreateOrder(data, actions)
                          }
                          onApprove={(data, actions) =>
                            onApproveOrder(data, actions)
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </center>
      <Footer />
    </>
  );
};

export default Preview;
