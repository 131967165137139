import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangePosts } from '../Service/Forgot';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';


const Forgot = () => {
    const [Email, setEmail] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('EmailAddress', Email);
        const response = await dispatch(ChangePosts(formData));
        console.log(response.payload)
        if (response.payload && response.payload.message) {
            toast.success(response.payload.message);
            navigate('/login')
        }
    };

    return (
        <center>
            <div className="login-container">
                <div className="login-header">
                    <h2 className="forgot-password-heading">Forgot Your Password?</h2>
                    <p className="forgot-password-text">Enter your email to reset password</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="email" id="email" name="email" placeholder="Email" required onChange={handlePasswordChange} />
                    </div>
                    <div className="form-group">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </center>

    );
};

export default Forgot;
