import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Tabs,
  Tab,
  Box,
  List,
  TextField,
  ListItem,
  FormControlLabel,
  Radio,
  Paper,
  Grid,
  Button,
  CardContent,
  Card,
} from "@mui/material";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { baseUrl, fetchPosts } from "../Service/Register";
import { Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AttachMoney,
  CalendarToday,
  Info,
  LocationOn,
} from "@material-ui/icons";
import PayPalButton from "./PayPalButton";
const useStyles = makeStyles((theme) => ({
  tabContainer: {
    paddingLeft: "30px",
    display: "flex",
    marginBottom: "50px",
  },
  tabButtons: {
    padding: "10px 10px",
    display: "flex",
    flexDirection: "column",
    width: "250px",
    gap: "10px",
  },
  tabButton: {
    fontWeight: "700 !important",
    marginBottom: "25px !important",
    backgroundColor: "inherit",
    color: "#00015D !important",
    border: "2px solid #00015D !important",
    borderRadius: "20px !important",
    outline: "none",
    padding: "10px 15px",
    textAlign: "left",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
    width: "100%",
    "&:hover": {
      backgroundColor: "#00015D !important",
      color: "#fff !important",
    },
    "&.Mui-selected": {
      backgroundColor: "#00015D !important",
      color: "#fff !important",
    },
  },
  tabContent: {
    flexGrow: 1,
    padding: "20px",
  },

  orderList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px", // Space between cards
  },
  orderCard: {
    display: "flex",
    flexDirection: "column",
    margin: "8px", // Margin around each card
    padding: "16px", // Padding inside the card
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
    borderRadius: "8px", // Rounded corners
    backgroundColor: "#ffffff", // White background
    border: "1px solid #ddd", // Light grey border

    transition: "background-color 0.3s, color 0.3s", // Smooth transition
    "&:hover": {
      backgroundColor: "#00015d", // Dark blue background on hover
      color: "#ffffff", // White text color on hover
    },
  },
  orderDetails: {
    display: "flex",
    flexDirection: "column",
  },
  orderField: {
    display: "flex",
    alignItems: "center",
    gap: "8px", // Space between icon and text
    marginBottom: "8px", // Space below each field
  },
  orderLabel: {
    fontWeight: "bold",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
const Account = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      handleLogout();
    }
  };

  const [Order, setOrder] = useState([]);
  const getOrder = async () => {
    const formData2 = new FormData();
    formData2.append("id", localStorage.getItem("Id"));

    try {
      const response = await fetch(`${baseUrl}Order/GetOrderByCustomerId`, {
        method: "POST",
        body: formData2,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const data = await response.json();
      if (data && data.success === true) {
        const result = data.data;
        setOrder(result);
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [addresses, setAddresses] = useState([]); // Initial list of addresses
  const [newAddress, setNewAddress] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [oldPass, setoldPass] = useState(null);
  const [newPass, setnewPass] = useState(null);
  const handleSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
  };

  const handleAddAddress = async () => {
    if (Object.values(newAddress).every((val) => val.trim())) {
      // Create a FormData object
      const formData1 = new FormData();
      formData1.append("CustomerId", localStorage.getItem("Id"));
      formData1.append("IsDefault", false);
      formData1.append("Address1", newAddress.address1);
      formData1.append("Address2", newAddress.address2);
      formData1.append("Country", newAddress.country);
      formData1.append("PostCode", newAddress.postCode);
      formData1.append("Recipient", newAddress.recipient);
      formData1.append("State", newAddress.state);
      formData1.append("Suburb", newAddress.suburb);

      try {
        // Send POST request to the backend
        const response = await fetch(`${baseUrl}Registration/AddAddress`, {
          method: "POST",
          body: formData1,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Optionally, you can update the UI with the new address
        const result = await response.json();
        toast.success(result.message);
        setAddresses([
          ...addresses,
          { ...newAddress, addressId: result.newAddressId, isDefault: false },
        ]);

        // Clear the new address form
        setNewAddress({
          address1: "",
          address2: "",
          country: "",
          postCode: "",
          recipient: "",
          state: "",
          suburb: "",
        });
      } catch (error) {
        console.error("Error adding address:", error);
      }
    }
  };

  const getAddress = async () => {
    try {
      const response = await fetch(
        `${baseUrl}Registration/GetCustomerAddressData/${localStorage.getItem(
          "Id"
        )}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = await response.json();
      if (data && data.success === true) {
        const result = data.data;
        setAddresses(result);
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [CustomerName, setCustomerName] = useState();
  const [EmailAddress, setEmailAddress] = useState();
  const [Password, setPassword] = useState();
  const [CPassword, setCPassword] = useState();
  const [HeardAbout, setHeardAbout] = useState();
  const [Subscribe, setSubscribe] = useState(0);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isOldPassValid, setIsOldPassValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setSubscribe(e.target.checked ? 1 : 0);
  };

  const [Data, setData] = useState([]);
  const getView = async () => {
    try {
      // const result = await GETAPIBYID(`GetCustomersData/${localStorage.getItem('Id')}`);
      try {
        const response = await fetch(
          `${baseUrl}Registration/GetCustomersData/${localStorage.getItem(
            "Id"
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.success === true) {
          const result = data.data;
          setData(result);
          setCustomerName(result.customerName);
          setEmailAddress(result.emailAddress);
        } else {
          return null;
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailAddress(value);

    // Basic email format validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const handleOldPassBlur = async () => {
    const formData = new FormData();
    formData.append("OldPassword", oldPass);

    try {
      const response = await fetch(`${baseUrl}Registration/CheckOldPassword`, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (!response.ok) {
        toast.error("Old password is incorrect");
      } else {
        setIsOldPassValid(true);
      }
    } catch (err) {
      setIsOldPassValid(false);
      toast.error(err.message);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("NewPassword", newPass);

    if (oldPass === newPass) {
      toast.error("Old and new passwords must not be the same.");
    } else if (!isOldPassValid) {
      setError("Please validate your old password first.");
    } else {
      try {
        const response = await fetch(`${baseUrl}Registration/ChangePassword`, {
          method: "PATCH",
          body: formData,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        if (response.ok) {
          toast.success("Password Successfully Updated");
          setoldPass("");
          navigate("/login");
          setnewPass("");
        }
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Pad single digit days with leading zero
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const todayDate = getTodayDate();
    formData.append("CustomerId", localStorage.getItem("Id"));
    formData.append("CustomerName", CustomerName);
    formData.append("EmailAddress", EmailAddress);
    formData.append("Password", Password);
    formData.append("HeardAbout", HeardAbout);
    formData.append("Subscribe", Subscribe);
    formData.append("AccessLevel", 1);
    formData.append("Deleted", false);
    formData.append("DateAdded", todayDate);

    try {
      const response = await fetch(`${baseUrl}Registration/EditCustomer`, {
        method: "PATCH",
        body: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = await response.json();

      if (data && data.success) {
        toast.success("Updated successfully");
        navigate("/login");
      } else {
        toast.error(response.payload.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handlePaymentSuccess = (details) => {
    toast.success("Payment Successful! Thank you for your purchase.");
  };

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);

    // Check if the user is authenticated, otherwise redirect to login page
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      // Fetch data only if the user is authenticated
      const fetchData = async () => {
        try {
          await Promise.all([getOrder(), getView(), getAddress()]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [navigate]); // Add `navigate` to the dependency array if it's a prop or needs to be updated

  return (
    <>
      <Header />
      <br />
      {/* <center> <h2 class="signh2">Login to Mosaics Photos</h2> </center> */}
      <div className={classes.tabContainer}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className={classes.tabButtons}
          scrollButtons={false}
        >
          <Tab
            label="My Account"
            {...a11yProps(0)}
            className={classes.tabButton}
          />
          <Tab
            label="Order History"
            {...a11yProps(1)}
            className={classes.tabButton}
          />

          <Tab
            label="Change Password"
            {...a11yProps(2)}
            className={classes.tabButton}
          />
          <Tab label="Logout" {...a11yProps(3)} className={classes.tabButton} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabContent}>
          <h2>My Account</h2>

          <div className="signcontainer">
            <h2 className="signh2">Update Account</h2>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-group">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Name"
                  value={CustomerName}
                  autoComplete="off"
                  required
                  onChange={(e) => setCustomerName(e.target.value)}
                />

                <input
                  type="text"
                  autoComplete="off"
                  value={EmailAddress}
                  aria-describedby="email-error"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <p
                    id="email-error"
                    style={{
                      color: "red",
                      fontSize: "small",
                      float: "inline-end",
                    }}
                  >
                    {emailError}
                  </p>
                )}
              </div>

              <Accordion sx={{ margin: "auto" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Addresses</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <List>
                      {addresses.map((address, index) => (
                        <ListItem key={address.addressId} dense>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  selectedAddressId === address.addressId
                                }
                                onChange={() =>
                                  handleSelectAddress(address.addressId)
                                }
                              />
                            }
                            label={
                              <Paper sx={{ padding: "8px", margin: "4px" }}>
                                <Typography variant="body2">
                                  <strong>Recipient:</strong>{" "}
                                  {address.recipient}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Address:</strong> {address.address1},{" "}
                                  {address.address2}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>State:</strong> {address.state}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Postcode:</strong> {address.postCode}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Country:</strong> {address.country}
                                </Typography>
                              </Paper>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <form autoComplete="off" onSubmit={handleAddAddress}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Recipient"
                          variant="outlined"
                          fullWidth
                          value={newAddress.recipient}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              recipient: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Address Line 1"
                          variant="outlined"
                          fullWidth
                          value={newAddress.address1}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              address1: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Address Line 2"
                          variant="outlined"
                          fullWidth
                          value={newAddress.address2}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              address2: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="State"
                          variant="outlined"
                          fullWidth
                          value={newAddress.state}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              state: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Country"
                          variant="outlined"
                          fullWidth
                          value={newAddress.country}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              country: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Post Code"
                          variant="outlined"
                          fullWidth
                          value={newAddress.postCode}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              postCode: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "8px" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Suburb"
                          variant="outlined"
                          fullWidth
                          value={newAddress.suburb}
                          onChange={(e) =>
                            setNewAddress((prev) => ({
                              ...prev,
                              suburb: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      onClick={handleAddAddress}
                      sx={{ marginTop: "8px" }}
                    >
                      Add Address
                    </Button>
                  </form>
                </AccordionDetails>
              </Accordion>

              <div className="form-group">
                <button type="submit">Update</button>
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabContent}>
          <Typography variant="h4" gutterBottom>
            Order History
          </Typography>
          {Order && Array.isArray(Order) && Order.length > 0 ? (
            Order.map((order) => (
              // <Link to={`/order-details/${order.orderNo}`}>
              <Card key={order.id} className={classes.orderCard}>
                <CardContent className={classes.orderDetails}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" className={classes.orderField}>
                        <CalendarToday />{" "}
                        <span className={classes.orderLabel}>Order Date:</span>{" "}
                        {new Date(order.orderDate).toLocaleDateString()}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <Info />{" "}
                        <span className={classes.orderLabel}>Order No:</span>{" "}
                        {order.orderNo}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>Total Price:</span>{" "}
                        {order.totalPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <LocationOn />{" "}
                        <span className={classes.orderLabel}>
                          Delivery Address:
                        </span>{" "}
                        {order.deliveryAddress}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>
                          Delivery Price:
                        </span>{" "}
                        {order.deliveryPrice}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>Item Price:</span>{" "}
                        {order.itemPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>Discount:</span>{" "}
                        {order.discount}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>
                          Extras Price:
                        </span>{" "}
                        {order.extrasPrice}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        <AttachMoney />{" "}
                        <span className={classes.orderLabel}>
                          Tracking URL:
                        </span>{" "}
                        {order.trackingUrl}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                        
                        <span className={classes.orderLabel}>
                        <PayPalButton
                          amount={order.totalPrice || 1}
                          onSuccess={handlePaymentSuccess}
                        />
                        </span>
                       
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        className={classes.orderField}
                      >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <PayPalButton
                          amount={order.totalPrice || 1}
                          onSuccess={handlePaymentSuccess}
                        />
                      </div>
                      </Typography>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
              // </Link>
            ))
          ) : (
            <div>No orders available</div> // Fallback message if no orders are available
          )}
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabContent}>
          <h2>Change Password</h2>
          <div className="signcontainer">
            <form onSubmit={handlePasswordSubmit} autoComplete="off">
              <div className="form-group">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Old Password"
                  value={oldPass}
                  autoComplete="off"
                  required
                  onBlur={handleOldPassBlur}
                  onChange={(e) => setoldPass(e.target.value)}
                />
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="New Password"
                  value={newPass}
                  autoComplete="off"
                  required
                  onChange={(e) => setnewPass(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button type="submit">Change Password</button>
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabContent}>
          <h2>Logout</h2>
          <p>Content for Logout.</p>
        </TabPanel>
      </div>
      <Footer />
    </>
  );
};

export default Account;
