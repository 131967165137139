import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    paddingLeft: '30px',
    display: 'flex',
    marginBottom: '50px',
  },
  tabButtons: {
    padding: '10px 10px',
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    gap: '10px',
  },
  tabButton: {
    fontWeight: '700 !important',
    marginBottom: '25px !important',
    backgroundColor: 'inherit',
    color: '#00015D !important',
    border: '2px solid #00015D !important',
    borderRadius: '20px !important',
    outline: 'none',
    padding: '10px 15px',
    textAlign: 'left',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    width: '100%',
    '&:hover': {
      backgroundColor: '#00015D !important',
      color: '#fff !important',
    },
    '&.Mui-selected': {
      backgroundColor: '#00015D !important',
      color: '#fff !important',
    },
  },
  tabContent: {
    flexGrow: 1,
    padding: '20px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const MyTabs = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabContainer}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabButtons}
        scrollButtons={false}
      >
        <Tab label="My Account" {...a11yProps(0)} className={classes.tabButton} />
        <Tab label="Saved Product" {...a11yProps(1)} className={classes.tabButton} />
        <Tab label="Completed Product" {...a11yProps(2)} className={classes.tabButton} />
        <Tab label="Profile" {...a11yProps(3)} className={classes.tabButton} />
        <Tab label="Logout" {...a11yProps(4)} className={classes.tabButton} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabContent}>
        <h2>My Account</h2>
        <p>{/* Content for My Account */}</p>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabContent}>
        <h2>Saved Product</h2>
        <p>Content for Saved Product.</p>
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabContent}>
        <h2>Completed Product</h2>
        <p>Content for Completed Product.</p>
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabContent}>
        <h2>Profile</h2>
        <p>{/* Content for Profile */}</p>
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.tabContent}>
        <h2>Logout</h2>
        <p></p>
      </TabPanel>
    </div>
  );
};

export default MyTabs;
